/* CONTACT STYLES */
.contact-top {
    padding-top: 250px;
    padding-bottom: 200px;
    position: relative;
    background-image: url('../img/background/contact.jpg');
    background-size: cover;
    background-attachment: fixed;
    .contact_inner {
        z-index: 2;
        position: relative;
    }

    .hero_title {
        font-size: 60px;
        margin-bottom: 30px;
        color: #fff;
    }

    p {
        color: #fff;
        margin: 0;
    }

}

.contact_top_img{

    margin-top: -250px;
    position: relative;
    height: 500px;
    z-index: 3;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        @include box-shadow;
    }
}
.contact_bottom{
    max-width: 900px;
    padding: 30px;
    margin: -60px auto 0;
    background-image: url("../img/background/bg1.jpg");
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    z-index: 6;
    position: relative;

    .stat_type2 .counter_content .stat_top .counter_text
    {
        color: #fff;
        font-size: 40px;
    }
    .stat_type2 .counter_content p{
        color: #fff;
        font-weight: normal;
    }
}

.contact-with-form{

    padding-top: 100px;
}
.form-control{
    padding: 10px 15px;
    margin-bottom: 15px;  
}
.button-area{
    .contact_out_text{
        margin-top: 10px;
        display: block;
    }
    .text-red{
        color: $rdn-primary-color;
    }
    .text-blue{
        color: $rdn-blue-color;
    }
}
/* google map styles */
.google_map{
    height: 430px;
    width: 100%;
    position: relative;
    z-index: 3;
    iframe{
        height: 100%;
        width: 100%;
    }
}

