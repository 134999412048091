/* Hero Style */
.hero {
    padding-top: 10%;
    padding-bottom: 7%;
    position: relative;
}

.top_wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    path {
        fill: lighten($color: $rdn-blue-color, $amount:68);

    }
}

.bottom-wave {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: #fff;

    path {
        fill: $rdn-gray-color;

    }
}


.hero_inner {
    position: relative;

    .hero_title {
        font-size: 60px;
        margin-bottom: 30px;
        color: $rdn-heading-color;
    }

    p {
        font-size: 20px;
    }

    .hero_img_outer {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;

        .hero_img {
            height: 500px;
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
            @include box-shadow;
            position: relative;
            z-index: 1;
        }

        .popup_video {
            background: #fff;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 100px;
            position: absolute;
            z-index: 33;
            right: 30px;
            font-size: 12px;
            top: 30px;
            transition: 0.3s;
            animation: ripple 1s infinite;

            &:hover {
                background-color: $rdn-primary-color;
                color: #fff;
            }
        }

        &::before {
            content: "";
            background: radial-gradient(circle, $rdn-primary-color 10%, transparent 10%), radial-gradient(circle, $rdn-primary-color 10%, transparent 10%);
            background-size: 20px 20px;
            background-position: 0 0, 100px 100px;
            width: 30%;
            height: 40%;
            position: absolute;
            top: -50px;
            left: -50px;
            z-index: 2;
        }

        &::after {
            content: "";
            background: lighten($color: $rdn-blue-color, $amount:68);
            width: 70%;
            height: 80%;
            position: absolute;
            z-index: 0;
            right: -30px;
            bottom: -30px;
            border-radius: 15px;
        }
    }


}


.text_slide {
    display: flex;

    strong {
        font-weight: $rdn-title-bold;

        span {
            color: $rdn-primary-color;
            display: flex;

        }

        &.swiper-slide-active {
            overflow: inherit;

            span {
                animation: slideInUp 1s ease;
            }
        }
    }
}

.glightbox-body_popupvideo {
    .gclose.gbtn {

        opacity: 1;
        width: 30px;
        height: 30px;
        top: 15px;
        position: absolute;
        right: 15px;
        background: rgba($color: #fff, $alpha: 0.1);
        padding: 10px;
        z-index: 3;

        path {
            fill: #fff;
        }
    }
}

.hero_title {
    font-size: 60px;
    margin-bottom: 30px;
}

.hero2 {
    padding-top: 15%;
    padding-bottom: 10%;
    background-image: url('../img/background/hero2.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

    .hero_inner {
        z-index: 2;
    }

    .hero_title {
        font-size: 60px;
        margin-bottom: 30px;
        color: #fff;
    }

    p {
        color: #fff;
    }

    .hero_bottom_box {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 100px;

        .popup_video {
            background: $rdn-primary-color;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 100px;
            z-index: 33;
            display: inline-block;
            font-size: 12px;
            color: #fff;
            transition: 0.3s;
            animation: ripple 1s infinite;
            margin-right: 30px;

            &:hover {
                color: $rdn-primary-color;
                background-color: #fff;
            }
        }

        p {
            font-size: 14px;
            font-weight: 400;
            border-bottom: 1px solid;

            a {
                color: #fff;
            }
        }
    }

}

.icon_hero_box{
    display: inline-flex;
    border-radius: 15px;
    @include box-shadow;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    bottom: 30px;
    left: 0;
    padding: 10px;
    background: #fff;
    i{
        font-size: 30px;
        justify-content: center;
        width: 50px;
        height: 50px;
        align-items: center;
        background-color: $rdn-primary-color;
        color: #fff;
        border-radius: 100px;
        align-self: center;
    }
    .icon_hero_content{
        padding: 10px;
        span{
            color:$rdn-primary-color;
            font-size: 14px;
            line-height: 1;
            display: block;
        }
        h3{
            margin-bottom: 0;
            font-size: 20px;
            line-height: 1;
        }
    }
}

.circle_border {
    border: 5px dashed lighten($color: $rdn-primary-color, $amount: 35);
    width: 150px;
    height: 150px;
    top: 9%;
    right: 2%;
    position: absolute;
    z-index: 0;
    border-radius: 100%;
    animation: hex_spin 10s linear infinite;
}