/* Global Styles */
body {
    line-height: 1.7;
    font-size: $rdn-fontsize-text;
    font-family: $rdn-paragraph-font;
    color: $rdn-text-color;
    font-weight: $rdn-text-regular;
    color: $rdn-text-color;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: $rdn-primary-color;
    text-decoration: none;
}

a:hover {
    color: $rdn-linkhover-color;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $rdn-title-font;
    color: $rdn-heading-color;
    font-weight: $rdn-title-bold;
    line-height: 1.2;
    margin-bottom: 30px;
    letter-spacing: $rdn-title-spacing;
}

p {
    margin-bottom: 30px;
}

p:last-child {
    margin-bottom: 0;
}

.content {
    padding-top: 100px;
    padding-bottom: 100px;
}

.content-mb {
    padding-top: 100px;
    padding-bottom: 70px;
}

.rdn_title {
    font-size: $rdn-fontsize-title;
}

.rdn_title_line {
    display: flex;

    &::before {
        content: "";
        width: 100px;
        height: 2px;
        background: $rdn-primary-color;
        display: block;
        margin-right: 15px;
        align-self: center;
    }
}


.rdn_subtext {
    color: $rdn-primary-color;
    font-size: 20px;
    margin-bottom: 20px;
}

.rdn_btn {
    font-size: 18px;
    color: #fff;
    line-height: 1;
    background-color: $rdn-primary-color;
    transition: 0.3s;
    font-weight: 200;
    padding: 20px 30px;
    display: inline-block;
    border-radius: 5px;
    transform: translateY(0);

    &-dark {
        color: #333;
    }

    &:hover {
        color: $rdn-primary-color;
        background-color: #fff;
        transform: translateY(-5px);
    }

    &:focus {
        box-shadow: none;
    }

    &_line {
        background: transparent;
        border: 2px solid #fff;
        padding: 16px 26px;
    }
}
.rdn_btn.dark_hover_btn{
    &:hover {
        background-color: $rdn-heading-color;
    }
}
.rdn_btn_dark{
    background-color: $rdn-heading-color;  
    &:hover {
        background-color: $rdn-primary-color;
        color:#fff;
    }
}

.rdn_subtitle {
    font-size: 28px;
}

.rdn_boldtext {
    font-weight: $rdn-text-bold;
    color: $rdn-bold-text-color;
}

.gray-bg {
    background-color: $rdn-gray-color;
}

.accordion-button:focus {
    box-shadow: none;
}

.blue-bg {
    background-color: $rdn-blue-color;
    background-image: url('../img/background/pattern2.png');
    background-position: bottom left;

    .rdn_title,
    p:not(.rdn_subtext) {
        color: #fff;
    }
}

.dark-bg {
    background-color: #0e293e;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin: 70px 0 100px 0;
}

.divider_footer {
    width: 100%;
    height: 1px;
    background-color: rgba($color: #fff, $alpha: 0.1);
    margin: 70px 0 60px 0;
}

.scrollToTopBtn {
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    text-align: center;
    right: 30px;
    bottom: 30px;
    background-color: #fff;
    color: $rdn-heading-color;
    @include box-shadow;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 5px;
    z-index: 33;

    &:hover {
        color: #fff;
        background-color: $rdn-heading-color;
    }
}

.zindex-2 {
    z-index: 2;
}

.popup_icon {
    font-size: 20px;
    background: $rdn-primary-color;
    color: #fff;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: 30px;
    transition: 0.3s;
    animation: ripple 1s infinite;

    &:hover {
        color: $rdn-primary-color;
        background-color: #fff;
    }
}

.typing-words {
    color: $rdn-primary-color;
}

/* ANIMATION */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3), 0 0 0 1em rgba(255, 255, 255, 0.2), 0 0 0 2em rgba(255, 255, 255, 0.1);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(255, 255, 255, 0.1), 0 0 0 2em rgba(255, 255, 255, 0.05), 0 0 0 3em rgba(255, 255, 255, 0);
    }
}

@keyframes hex_spin {
    from {
        transform: rotate(0);
        transform-origin: center;
    }

    to {
        transform: rotate(360deg);
    }

}


.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: darken($color: #261C2C, $amount: 45), $alpha: 0.5);
    z-index: 1;
    left: 0;
    top: 0;
}

.mask_dark {
    background-color: rgba($color: darken($color: #261C2C, $amount: 55), $alpha: 0.7);
}

.mask_gradient {
    background: linear-gradient(360deg, rgba($color: #111, $alpha: 0.9) 26%, rgba(36, 45, 57, 0.7) 74%, rgba(50, 44, 50, 0.4)100%);
}

.mask_gray {
    background-color: rgba($color: darken($color: $rdn-gray-color, $amount: 80), $alpha: 0.8);
}

.mask_primary {
    background-color: rgba($color: darken($color: $rdn-primary-color, $amount: 45), $alpha: 0.9);
}
.mask_mobile{
    background-color:transparent;
}

/* MARGIN & PADDING */
.mb-30 {
    margin-bottom: 30px;
}
.mt-30{
    margin-top: 30px;
}
.mb-60 {
    margin-bottom: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-100 {
    margin-bottom: 100px;
}
.mmb-100 {
    margin-bottom: -100px;
}
.mmb-200 {
    margin-bottom: -200px;
}
.mb-200 {
    margin-bottom: 200px;
}
.mt-100 {
    margin-top: 100px;
}
.mt-200{
    margin-top: 200px;
}
.ml-30{
    margin-left: 30px;
}
.mr-30{
    margin-right: 30px;
}
.pt-100{
    padding-top: 100px;
}

.pt-200 {
    padding-top: 200px;
}

.pb-100{
    padding-bottom: 100px;
}
.rdn_list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin-bottom: 15px;
        padding: 0;
        display: flex;
        align-items: flex-start;

        &::before {
            content: "\f00c";
            font-weight: bold;
            font-family: "Font Awesome 5 Free";
            color: $rdn-primary-color;
            background-color: lighten($color: $rdn-primary-color, $amount: 37);
            font-size: 9px;
            height: 22px;
            line-height: 22px;
            width: 22px;
            text-align: center;
            margin: 5px 10px 0 0;
            border-radius: 50px;
            flex-shrink: 0;
        }
    }
}

.bg_text {
    color: #fff;
    font-size: 120px;
    position: absolute;
    transform: rotate(-90deg);
    left: 0%;
    bottom: 0%;
    transform-origin: 0 0;
    z-index: 0;

    &:after {
        content: "";
        width: 200px;
        height: 2px;
        background: #fff;
        position: absolute;
        right: 100%;
        top: 50%;
        margin-right: 50px;
    }

}

.circle {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: 4rem solid lighten($color: $rdn-primary-color, $amount: 37);
    position: absolute;
    margin-bottom: -150px;
    bottom: 50%;
}

.circle_left {
    left: -100px;
}

.circle_right {
    right: -100px;
}

.circle_white {
    border-color: #fff;
}

.progress-bar {
    width: 0%;
}

.bg-encox {
    background-color: $rdn-primary-color;
}


.demo_item{
    margin: 30px;
    
    .event_top{
        height: 480px;
        background-color: transparent;
        img{
            object-position: top;
            
        }
        i{
            color:  $rdn-primary-color;
        }
        &:hover{
            img{
                transform: rotate(0) scale(1.01);
                opacity: 0.8;
            }
        }
    }
    .bottom_text{
        text-align: center;
    }
}
