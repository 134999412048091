/* STATS STYLE */
.stats_number {

    .stat_bg {
        position: relative;
        padding-top: 100px;
        padding-bottom: 200px;
    }


    .container {
        position: relative;

        .stat_relative {
            z-index: 2;
            position: relative;
            z-index: 3;
            margin-top: -100px;
            width: 100%;
            left: 0;
        }

    }


    .stat_inner {

        font-size: 18px;
        line-height: 2;
        border-radius: 15px;
        position: relative;
        z-index: 2;
        margin: 0 15px;

        .stat_content {
            padding: 100px 100px 100px 50px;
            background-image: url('../img/background/bg1.jpg');
            border-radius: 15px;
            background-size: cover;
            background-position: right top;
            @include box-shadow;

            &::after {
                content: "";
                background: linear-gradient(-45deg, #efc2cc 1%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0) 49%, #efc2cc 49%, #efc2cc 51%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 99%, #efc2cc 99%);
                background-size: 7px 7px;
                background-position: 50px 50px;
                width: 300px;
                height: 80%;
                position: absolute;
                z-index: -1;
                left: -45px;
                top: -30px;
                border-radius: 15px;
                transform: translateZ(-20px);
            }

            .counter_content {
                border-right: 1px solid rgba($color: #fff, $alpha: 0.03);

                &:last-child {
                    border-right: none;
                }

                i {
                    background-color: $rdn-primary-color;
                    color: #fff;
                    width: 60px;
                    height: 60px;
                    text-align: center;
                    line-height: 60px;
                    margin-bottom: 15px;
                    border-radius: 100px;
                }

                span {
                    display: block;
                    line-height: 1;
                    font-weight: 600;
                    color: #fff;
                    font-size: 45px;
                }

                p {
                    color: $rdn-primary-color;
                    font-size: 15px;
                }
            }
        }

        .stat_img {
            position: relative;
            transform: perspective(1000px);
            transform-style: preserve-3d;

            .counter_boxbg {
                border-radius: 10px;
                width: 130%;
                height: calc(100% - 60px);
                min-height: 200px;
                position: absolute;
                z-index: 3;
                right: 0;
                margin: 30px 0;
                @include box-shadow;

                img {
                    object-fit: cover;
                    border-radius: 10px;
                    max-width: none;
                    width: 100%;
                    height: 100%;
                }
            }



            .popup_video {
                background: #fff;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                border-radius: 100px;
                position: absolute;
                z-index: 33;
                font-size: 12px;
                bottom: 60px;
                right: 30px;
                transition: 0.3s;
                animation: ripple 1s infinite;
                transform: translateZ(20px);

                &:hover {
                    background-color: $rdn-primary-color;
                    color: #fff;
                }
            }
        }
    }

}

.stat_type2 {
    .counter_content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: center;

        .stat_top {
            position: relative;

            .counter_text {
                font-size: 50px;
                font-weight: $rdn-title-bold;
                font-family: $rdn-title-font;
                color: $rdn-heading-color;
            }

            i {
                color: $rdn-primary-color;
                border: 1px solid;
                width: 30px;
                height: 30px;
                font-size: 13px;
                align-items: center;
                justify-content: center;
                display: flex;
                border-radius: 100px;
                position: absolute;
                left: 100%;
                margin-left: 5px;
            }
        }

        p {
            align-self: flex-start;
            width: 100%;
            flex-shrink: 0;
            text-transform: uppercase;
            font-size: 13px;
        }
    }
}