// for flex center
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

//for slider transition 
@mixin transition_slide {
    opacity: 0;
    transition: ease-in .2s;
    transform: translateY(50px);
}

//box shadow
@mixin box-shadow {
    box-shadow: 0 3px 15px 2px rgba($color: #333, $alpha: 0.1);
}

@mixin box-shadow-dark {
    box-shadow: 0 3px 15px 2px rgba($color: #333, $alpha: 0.7);
}

@mixin box-shadow-light {
    box-shadow: 0 3px 8px 2px rgba($color: #aaa, $alpha: 0.1);
}