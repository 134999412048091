/* Services style 1 */
.text_icon {


    .top_icon {
        position: relative;
        height: 300px;
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
        background-color: #000;

        &:hover img {
            transform: scale(1.2) rotate(-2deg);
            opacity: 0.3;

        }

        img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
            transform: scale(1) rotate(0);
            transition: transform 0.6s ease-in, opacity 0.3s ease-in-out;
            opacity: 1;

        }

        p {
            position: absolute;
            background-color: $rdn-primary-color;
            color: #fff;
            top: 30px;
            left: 30px;
            font-size: 12px;
            line-height: 1;
            padding: 7px 14px;
            border-radius: 5px;
            ;

        }
    }

    .bottom_icon {
        margin: -60px 30px 0 30px;
        background: #fff;
        padding: 30px;
        display: flex;
        z-index: 33;
        position: relative;
        border-radius: 10px;
        justify-content: space-between;
        @include box-shadow;

        p {
            color: $rdn-primary-color;
            font-size: 15px;
            font-weight: 200;
            margin-bottom: 5px;
        }
    }




    .rdn_subtitle {
        font-size: 22px;
        margin: 0;
    }



    .icon_service {
        color: #fff;
        font-size: 16px;
        background: $rdn-primary-color;
        height: 50px;
        width: 50px;
        align-self: center;
        line-height: 50px;
        text-align: center;
        flex-shrink: 0;
        border-radius: 100px;
    }
}


.bg_dotted_img {
    background-image: radial-gradient(circle, $rdn-primary-color 15%, transparent 10%), radial-gradient(circle, $rdn-primary-color 15%, transparent 10%);
    background-size: 25px 25px;
    background-position: 0 0, 50px 50px;
    width: 300px;
    height: 200px;
    position: absolute;
    bottom: -42px;
    left: -30px;
    z-index: 0;
    opacity: 0.3;
    &.right_top_img{
        left: auto;
        right:-30px;
        top:-42px;
        bottom: auto;
    }
    &.left_top_img{
        top:-42px;
        bottom: auto;
    }
}

.left_img {
    .bg_dotted_img {
        left: auto;
        right: -30px;
    }
}

.services_small {
    position: relative;
    padding-top: 100px;

    .ssmall_inner {
        display: flex;
        padding: 15px;
        @include box-shadow-light;
        border-radius: 100px;
        background-color: #fff;
        transition: 0.3s;

        i {
            align-self: center;
            color: $rdn-primary-color;
            font-size: 16px;
            background-color: lighten($color: $rdn-primary-color, $amount: 37);
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            flex-shrink: 0;
            border-radius: 100px;
            margin-right: 10px;
        }

        &_textbox {
            align-self: center;

            p {
                margin: 0;
                line-height: 1;
                color: $rdn-primary-color;
                transition: 0.3s;
            }

            h3 {
                margin: 0;
                font-size: 20px;
                transition: 0.3s;
            }
        }

        &:hover {
            transform: translateY(-10px);
            background-color: $rdn-heading-color;
            @include box-shadow;

            .ssmall_inner_textbox {
                h3 {
                    color: $rdn-primary-color;
                }

                p {
                    color: #fff;
                }
            }
        }
    }
}


.srv_icon {
    .icon_bg {
        margin-right: 30px;

        i {
            color: $rdn-primary-color;
            background: lighten($color: $rdn-primary-color, $amount: 37);
            width: 60px;
            height: 60px;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            font-size: 35px;

        }
    }

    h3 {
        margin-bottom: 15px;
        font-size: 22px;
        letter-spacing: -0.02em;
    }

    p {
        margin-bottom: 0;
    }
}


.services_small2 {
    .ssmall2_inner {
        margin-top: -50px;
        z-index: 3;
        position: relative;

        .ssmall_inner_textbox {
            padding: 30px;
            @include box-shadow;
            background-color: #fff;
            border-radius: 100px;

            .srv_icon {
                align-items: center;

                .srv_content {

                    h3,
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.service2 {
    overflow: hidden;
    position: relative;

    .container {
        position: relative;
        z-index: 1;
    }

    .srv_icon {
        .icon_bg {
            i {
                border-radius: 15px;
            }
        }
    }
}

.service_inner {
    .left_side {
        margin-left: -40vh;
        position: relative;

        img {
            border-radius: 15px;
            min-height: 70vh;
            object-fit: cover;
            position: relative;
            z-index: 1;
        }

        .box_img {
            content: "";
            background-image: radial-gradient(circle, $rdn-primary-color 15%, transparent 10%), radial-gradient(circle, $rdn-primary-color 15%, transparent 10%);
            background-size: 25px 25px;
            background-position: 0 0, 50px 50px;
            width: 300px;
            height: 200px;
            position: absolute;
            bottom: -50px;
            right: -50px;
            z-index: 0;
            opacity: 0.3;
        }
    }
}

.service_box {
    border: 1px solid #eee;
    @include box-shadow;
    border-radius: 15px;
    position: relative;
    transition-delay: 0.2s;
    transition: 0.5s;


    .sv_inner {
        border-radius: 15px;
        transition: 0.3s;
        padding: 30px;
        position: relative;
        overflow: hidden;
        background-color: #fff;

        .sv_top {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #eee;


            i {
                height: 60px;
                width: 60px;
                transition: 0.2s;
                font-size: 30px;
                color: $rdn-primary-color;
                align-items: center;
                justify-content: center;
                background-color: #f4f4f4;
                margin-right: 30px;
                border-radius: 100px;
                flex-shrink: 0;
            }

            &_head {


                h3 {
                    transition: 0.3s;
                    margin: 0;
                    font-size: 22px;
                }

                .sub_text {
                    margin: 0;
                    color: #aaa;
                }
            }

            .desc_text {
                transition: 0.4s;
            }

        }

        .sv_bg {
            position: absolute;
            transition: 0.1s;
            bottom: -40px;
            z-index: 0;
            right: 30px;
            font-size: 150px;
            color: lighten($color: $rdn-primary-color, $amount: 37);
        }
    }

    &:hover {
        transform: translateY(-10px);

        .sv_inner {
            background-color: $rdn-blue-color;

            .sv_top {
                h3 {
                    color: #fff;
                }

                i {
                    color: #fff;
                    background-color: $rdn-primary-color;
                }
            }

            .desc_text {
                color: #fff;
            }

            .sv_bg {
                opacity: 0.1;
            }
        }
    }
}

.abimg_top {
    position: relative;
    z-index: 1;
}

.ab_bg {
    position: absolute;
    top: 0;
    left: 0;
    animation: hex_spin 10s linear infinite;
}

.progress_text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
        font-size: 14px;
        font-weight: $rdn-text-bold;
    }

    i {
        justify-content: center;
        align-items: center;
        color: $rdn-primary-color;
        font-size: 14px;
        background-color: lighten($color: $rdn-primary-color, $amount: 37);
        height: 25px;
        width: 25px;
        text-align: center;
        flex-shrink: 0;
        border-radius: 100px;
        margin-right: 10px;
    }
}


.service_box_two{
    
    i{
        font-size: 25px;
        background-color: $rdn-primary-color;
        color: #fff;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
    }
    h3{
        font-size: 20px;
        margin: 0;
    }
    .sub_text{
        margin-bottom: 30px;
        margin-top: 0;
        color: $rdn-primary-color;
    }
    .sv_top{
        background-color: #fff;
        border-radius: 15px;
        padding: 60px 30px 30px 30px;
        margin-top: -30px;
        @include box-shadow;
    }
    .sv_dot{
        bottom: -40px;
        left: -40px;
        background: radial-gradient(circle, $rdn-primary-color 10%, transparent 10%), radial-gradient(circle, $rdn-primary-color 10%, transparent 10%);
        background-size: 20px 20px;
        background-position: 0 0, 100px 100px;
        width: 30%;
        height: 40%;
        position: absolute;
        top: -10px;
        left: -50px;
        z-index: 0;
    }
}