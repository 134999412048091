@media (max-width: 991px) {
    .slide_content h1 {
        font-size: 55px;
    }

    .stat_counter {
        .stat_img_main {
            width: 100%;
            margin-left: 0;
        }

        &:before,
        &:after {
            display: none;
        }

        .stat_box {
            margin-left: 30px;
        }
    }

    .hero {
        padding-top: 150px;
        padding-bottom: 100px;


        .hero_inner {


            .hero_title {
                font-size: 55px;
                max-width: 500px;
            }

            .rdn_btn {
                margin-bottom: 30px;
            }

            p {
                max-width: 500px;
            }

            .hero_img_outer {

                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }

    .hero2 {
        padding-top: 150px;
        padding-bottom: 100px;

        .hero_inner {
            .hero_title {
                font-size: 50px;
            }
        }
    }

    .rdn_title_line {
        flex-direction: column-reverse;

        &:before {
            align-self: start;
            margin-top: 30px;
            height: 1px;
        }
    }

    .events_slide {
        .eventslide_box {
            margin-right: 0;
        }
    }

    .service_inner {
        .left_side {
            margin-left: 0;

            .box_img {
                display: none;
            }
        }
    }

    .stats_number {
        .stat_inner {
            .stat_content {
                .counter_content {
                    border: none
                }
            }

            .stat_img {
                .counter_boxbg {
                    position: relative;
                    width: 100%;
                }
            }
        }
    }

    .stats_number {
        .stat_inner {
            .stat_content {
                padding: 60px;
            }
        }
    }

    .team_box_special {

        &::after {
            display: none;
        }


    }

    .circle,
    .bg_text {
        display: none;
    }
    .container {
        padding-right: 30px;
        padding-left: 30px;
    }

    .footer {
        background-size: auto;
        background-position: left top;
    }

    .hero .hero_inner .hero_title,.hero_title{
        max-width: none;
        font-size: 45px;
    }
    .hero-yellow {
        padding-top: 100px;
        padding-bottom: 100px;
    } 
    .hero-blob{
        background-size: 100%;
    }
    .contact_bottom{
        max-width: 600px;
    }
    .about_widget img{
        height: 300px;
    }
}

@media (max-width: 767px) {
    .slide_content h1 {
        font-size: 40px;
    }

    .slide_content {
        padding-top: 25%;
        padding-bottom: 20%;
    }

    .rdn_title {
        font-size: 40px;
    }
    .mask_mobile{
        background-color: rgba($color: darken($color: #261C2C, $amount: 55), $alpha: 0.7);
    }
   
    .ab_img_page{
        height: 300px;
    }
    

}

@media (max-width: 500px) {
    .slide_content {
        padding-top: 35%;
        padding-bottom: 20%;
    }
    .contact_bottom{
        max-width: 80%;
    }
    .hero_inner .hero_title{
        font-size: 50px;
    }
    .about_widget img{
        height: 200px;
    }
    .single_blog_meta ul li {
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;    
        }
    }
    .comment_list li .top_comment .top_comment_content a{
        position: relative;
        margin-top: 10px;
        display: inline-flex;
    }
}