/* Team Styles */
.team_box {
    border-radius: 10px;
    @include box-shadow;
    position: relative;

    img {
        border-radius: 10px;
        height: 400px;
        object-fit: cover;
        width: 100%;
    }

    .team_sc {
        position: absolute;
        top: 30px;
        right: 30px;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                background-color: $rdn-primary-color;
                color: #fff;
                display: block;
                border-radius: 100px;
                font-size: 12px;
                @include box-shadow;

                &:hover {
                    color: $rdn-primary-color;
                    background-color: #fff;
                }
            }
        }
    }
}

.team_desc {
    margin: -60px 30px 0;
    @include box-shadow;
    padding: 30px;
    border-radius: 7px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    overflow: hidden;

    h3 {
        font-size: 20px;
    }

    p {
        color: $rdn-primary-color;
    }

    &::before {
        content: "";
        height: 80px;
        width: 80px;
        position: absolute;
        background: lighten($color: $rdn-primary-color, $amount: 33);
        right: -60px;
        border-radius: 80px;
        bottom: -15px;
    }

    &::after {
        content: "";
        width: 100px;
        height: 100px;
        background: lighten($color: $rdn-primary-color, $amount: 37);
        display: block;
        border-radius: 100px;
        position: absolute;
        right: -75px;
        bottom: -50px;
    }
}

.team_box_special {
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        background: radial-gradient(circle, $rdn-primary-color 10%, transparent 10%), radial-gradient(circle, $rdn-primary-color 10%, transparent 10%);
        background-size: 20px 20px;
        background-position: 0 0, 100px 100px;
        width: 30%;
        height: 80%;
        position: absolute;
        bottom: 30px;
        right: -20px;
        z-index: -1;
    }
}

.team_hexa {
    .team_box {
        box-shadow: none;

        img {
            width: 100%;
            height: auto;
        }

        .team_sc {
            position: absolute;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            top: 0;
            right: 20px;
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: end;
            justify-content: center;
        }
    }

    .team_desc::after,
    .team_desc::before {
        display: none;
    }

    .team_desc {
        padding: 15px;
        border-radius: 5px;
        margin: -55px 0 0px 0;
        max-width: 140px;
    }

    h3 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
        line-height: 1.2;
    }
}